import { isSubscriptionTypeValid } from './subscription';


export const centsToDollars = (amt) => Math.round(amt / 100.0);

export const subscriptionBasePriceCents = {
    ALL:               69500,   // per year
    SHIFTS_EMAIL_ONLY: 1500,    // per month
    FREE:              0
};


export const subscriptionPriceCents = ({
    basePriceCents = null, couponData = null, subscriptionType = "ALL"
}) => {

    if ( ! isSubscriptionTypeValid(subscriptionType) )
        throw new Error("util.price.js/subscriptionPriceCents: subscriptionType " + subscriptionType + " is not valid");
    
    // find base price
    
    if ( basePriceCents === null || basePriceCents === undefined ) {
        basePriceCents = subscriptionBasePriceCents[subscriptionType];
    }
    if ( typeof basePriceCents !== 'number' ) {
        basePriceCents = Number(basePriceCents);
    }


    // handle coupon
    
    const d = couponData;
    if ( ! d
         || typeof d !== 'object'
         || Object.keys(d).length === 0
         || ( ! d.valid
              && ( ! d['duration_type']  // probably invalid coupon data
                   || ( d['duration_type'].toLowerCase() !== 'forever'
                        && d['duration_type'].toLowerCase() !== 'repeating' ) ) ) )
        // TODO to properly handle repeating, we also need to check
        // the number of months remaining (d['duration_in_months'])
        return basePriceCents;

    const percentOff = d['percent_off'];
    const amountOff = d['amount_off'];
    const reductAmt = percentOff ? (percentOff / 100.0) * basePriceCents : amountOff;

    
    // subtract coupon amount from base price
    
    return basePriceCents - reductAmt;
};
