
export const subscriptionPeriod = {
    ALL:               "year",
    FREE:              "year",   // these can be custom lengths, but we'll use "year" by default
    SHIFTS_EMAIL_ONLY: "month"
};

export const subscriptionTypesAll = ["ALL", "FREE", "SHIFTS_EMAIL_ONLY"];

export const isSubscriptionTypeValid = (subs_type) => {

    const s = typeof subs_type === 'string' ? subs_type : String(subs_type);
    for (const st of subscriptionTypesAll) {
        if ( s === st ) return true;
    }
    return false;
};
