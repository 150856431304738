import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

import { newEvent } from '../utils/event';
import { refreshSessionId } from '../utils/session_id';

import { RenderHead } from "../components/head";
import { Membership } from '../components/public_home/membership';



//
// configuration

const originType = process.env.ORIGIN_TYPE;
const memberContent = originType === 'private' || originType === 'expired' || originType === 'shifts-email-only';

const title = memberContent ? "You're already a Member" : "Become a Member";
export const Head = () => RenderHead({ title });




//
// component

const Join = ({ data, location }) => {

    const userFields = data.allUserFieldsJson.edges[0].node;
    
    useEffect(() => {
        newEvent({
            'level':           'trace',
            'event.namespace': 'web.join',
            'event.name':      'loaded'
        });
    }, []);

    useEffect(() => { refreshSessionId(); }, []);
    
    return (
        <Layout
            location={ location }
            removeHorizontalPadding={ false } >
            
            { Membership({ userFields }) }

        </Layout>
    );
};

export default Join;


export const pageQuery = graphql`
  query {
    allUserFieldsJson {
      edges {
        node {
          market_events
          market_sectors
          signals_selected
          shifts_researched
          quick_hits
        }
      }
    }
  }
`;
